html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

/*
 * Remove the rubberBand
 * https://stackoverflow.com/questions/29008194/disabling-androids-chrome-pull-down-to-refresh-feature/47709903#47709903
 */
body {
  overscroll-behavior: contain;
}

/*
 * TODO: Remove from Schuldenberg
 */
.webfonticon {
  overflow: visible !important;
}
